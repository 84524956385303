import {
    Box,
    Button,
    Checkbox,
    Container,
    HStack,
    Input,
    Radio,
    RadioGroup,
    Select,
    SelectField,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import {graphql, Link, navigate} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import {useEffect} from "react";
import Footer from "../components/footer";
import NavBar from "../components/header";
import {SEO} from "../components/seo";
import {Colors} from "../config";
import {getPlans} from "../services/api/bidbox";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

const SelectContainer = (props: {
    title: string;
    subtitle?: string;
    values: Record<string, string>;
    onChange: Function;
}) => {
    const {title, subtitle, values, onChange} = props;

    return (
        <Box borderWidth={1} w="full" p={8} borderColor="#beeb9e" borderRadius="md" style={{
            boxShadow: "0 8px 12px -1px rgba(190, 235, 158, 0.8),0 4px 8px -1px rgba(190, 235, 158, 0.6)"
        }}>
            <Text mb={2} fontSize="xl">
                {title}
            </Text>
            {subtitle && <Text fontSize="md" pl="1" pb="2"> {subtitle} </Text>}
            <RadioGroup onChange={(value) => onChange(value)} name={title}>
                <VStack alignItems="flex-start">
                    {Object.keys(values).map((key: string) => (
                        <Radio value={key} key={key}>
                            {values[key]}
                        </Radio>
                    ))}
                </VStack>
            </RadioGroup>
        </Box>
    );
};

const ListingSellerCoverageTerms = (props: { onChange: Function }) => {
    const [values, setValues] = React.useState([false, false]);

    React.useEffect(() => {
        props.onChange(values[0] && values[1]);
    }, [values]);

    const onChange = (value: boolean, index: number) => {
        const newValues: boolean[] = Object.assign([], values);
        newValues[index] = value;
        setValues(newValues);
    };

    return (
        <Box borderWidth={1} w="full" p={8} borderColor="#beeb9e" borderRadius="md" style={{
            boxShadow: "0 8px 12px -1px rgba(190, 235, 158, 0.8),0 4px 8px -1px rgba(190, 235, 158, 0.6)"
        }}>
            <Text mb={2} fontSize="xl">
                Listing/Seller's Coverage Terms...
            </Text>
            <VStack alignItems="flex-start">
                <Text>
                    Listing/Seller's Coverage is effective upon receipt of application.
                    Plan continues until expiration of the initial listing period not to
                    exceed 180 days or until listing cancellation or close of sale,
                    whichever occurs first. AHW, in its sole discretion, may extend
                    coverage period. Pre-existing conditions are not covered for the
                    Seller. Optional coverages set forth in this Contract are not
                    available for Seller’s Coverage. If a claim is made by the Seller
                    during the listing period a service fee will be required and paid by
                    the Seller. If the property does not close and a claim has been made
                    during the listing period, the Seller is responsible for full payment
                    of services rendered or full payment of the home warranty coverage
                    plan whichever is less.
                </Text>
                <Checkbox
                    onChange={(e) => onChange(e.target.checked, 0)}
                    checked={values[0]}
                >
                    Yes. I agree to these terms.
                </Checkbox>
                <Checkbox
                    onChange={(e) => onChange(e.target.checked, 1)}
                    checked={values[1]}
                >
                    I understand the invoice I will receive when this form is completed is
                    just for my records. No monies are due until closing. I will notify
                    Acclaimed Home Warranty when a buyer has been identified.
                </Checkbox>
            </VStack>
        </Box>
    );
};

const CoverageQuestions = (props: { onChange: Function }) => {
    const warrantyOrders: Record<string, string> = {
        single: "Single-family home",
        duplex: "Duplex",
        triplex: "Triplex",
        fourplex: "Fourplex",
        mobilehome: "Condominium, townhome, or mobile home",
    };

    const squareOptions: Record<string, string> = {
        yes: "Yes",
        no: "No",
    };

    const constructionOptions: Record<string, string> = {
        no: "No",
        yes: "Yes",
    };

    const [homeType, sethomeType] = React.useState("");
    const [square, setSquare] = React.useState("yes");
    const [isNewHome, setNewHome] = React.useState("no");

    React.useEffect(() => {
        props.onChange({
            homeType,
            square: square == "yes",
            isNewHome: isNewHome == "yes",
        });
    }, [homeType, square, isNewHome]);


    const onOrderChanged = (val: string) => {
        sethomeType(val);
    };

    const onSquareChanged = (val: string) => {
        if (val == "no" || val == "yes") {
            setSquare(val);
            return;
        }

        setSquare("yes");
        sethomeType(val);
    };

    const onNewHomeChanged = (val: string) => {
        if (val == "no" || val == "yes") {
            setNewHome(val);
            return;
        }
        setNewHome("no");
        sethomeType(val);
    };

    return (
        <Box borderWidth={1} w="full" p={8} borderColor="#beeb9e" borderRadius="md" style={{
            boxShadow: "0 8px 12px -1px rgba(190, 235, 158, 0.8),0 4px 8px -1px rgba(190, 235, 158, 0.6)"
        }}>
            <HStack alignItems="flex-start">
                <VStack spacing={3} alignItems="flex-start">
                    <VStack alignItems="flex-start">
                        <Text>This home warranty order is for a:</Text>
                        <RadioGroup
                            name="warrantyOrder"
                            value={homeType}
                            onChange={(value) => onOrderChanged(value)}
                        >
                            <VStack alignItems="flex-start">
                                {
                                    Object.keys(warrantyOrders).map((key: string) => (
                                            <Radio value={key} key={key}>
                                                {warrantyOrders[key]}
                                            </Radio>
                                        )
                                    )}
                            </VStack>
                        </RadioGroup>
                    </VStack>
                    {/*<VStack>*/}
                    {/*  {homeType === "single" && <Radio value="single">{warrantyOrders.single}</Radio>}*/}
                    {/*</VStack>*/}

                    {homeType == "single" && (
                        <VStack alignItems="flex-start">
                            <Text>
                                Is the area of this home less than 6,001 square feet?
                            </Text>
                            <RadioGroup
                                name="squareFootage"
                                value={square}
                                onChange={(value) => onSquareChanged(value)}
                            >
                                {square == "yes" ? (
                                    <VStack alignItems="flex-start">
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </VStack>
                                ) : (
                                    <VStack alignItems="flex-start">
                                        {Object.keys(squareOptions).map((key: string) => (
                                            <Radio value={key} key={key}>
                                                {squareOptions[key]}
                                            </Radio>
                                        ))}
                                    </VStack>
                                )}
                            </RadioGroup>
                        </VStack>
                    )}
                    {/* {homeType == "single" && square == "yes" && (
            <VStack alignItems="flex-start">
              <Text>Is this a new construction home?</Text>
              <RadioGroup
                name="newConstructionHome"
                value={isNewHome}
                onChange={(value) => onNewHomeChanged(value)}
              >
                <VStack alignItems="flex-start">
                  {Object.keys(constructionOptions).map((key: string) => (
                    <Radio value={key} key={key}>
                      {constructionOptions[key]}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </VStack>
          )} */}
                </VStack>
            </HStack>
        </Box>
    );
};

const FinalSteps = (props: any) => {

    let {homeType, under6K, newHome, agentType} = props;
    const [inputValue, setInputValue] = React.useState(0);
    const minValue = React.useMemo(() => {
        const Mins: Record<string, number> = {
            single: 300,
            duplex: 595,
            triplex: 795,
            fourplex: 995,
            mobilehome: 285,
        };
        return Mins[homeType] || 0;
    }, [homeType]);

    React.useEffect(() => {
        setInputValue(minValue);
    }, [homeType]);

    const onChange = (val: string) => {
        setInputValue(Number(val));
    };

    const onGo = () => {
        let numVal = Number(inputValue);
        if (!numVal) numVal = 0;
        const newValue = Math.min(Math.max(minValue, minValue), 2000);
        props.onContinue(newValue)
    };

    return (<>
        <Button
            bg={Colors.green}
            color={useColorModeValue("white", "gray.800")}
            _hover={{
                bg: "green.600",
            }}
            onClick={onGo}
        >
            Next
        </Button>
    </>)
    // if (homeType == "single") {
    //   if (square)
    //     if (!isNewHome) {
    //       return (
    //         <Box
    //           borderWidth={1}
    //           w="full"
    //           p={8}
    //           borderColor="#00a1b8"
    //           borderRadius="md"
    //         >
    //           <HStack alignItems="flex-start" spacing={3}>
    //             <VStack alignItems="flex-start">
    //               <Text mb={2} fontSize="xl">
    //                 Let our system select the best plan
    //               </Text>
    //               <Text>Enter Your Home Warranty Budget</Text>
    //               <Input
    //                 type="number"
    //                 min={minValue}
    //                 max={2000}
    //                 value={inputValue}
    //                 onChange={(e) => onChange(e.target.value)}
    //               />
    //               <Text>Enter an amount from ${minValue} - $2000</Text>
    //               <Button
    //                 bg={Colors.green}
    //                 color={useColorModeValue("white", "gray.800")}
    //                 _hover={{
    //                   bg: "green.600",
    //                 }}
    //                 onClick={onGo}
    //               >
    //                 GO
    //               </Button>
    //             </VStack>
    //             <Text>OR</Text>
    //             <VStack spacing={3} alignItems="flex-start">
    //               <Button
    //                 bg={Colors.green}
    //                 color={useColorModeValue("white", "gray.800")}
    //                 _hover={{
    //                   bg: "green.600",
    //                 }}
    //               >
    //                 CLICK HERE...
    //               </Button>
    //               <Text>
    //                 to Compare Home Warranty Plans Side-By-Side
    //                 <br /> and make your selection/customize
    //               </Text>
    //             </VStack>
    //           </HStack>
    //         </Box>
    //       );
    //     } else {
    //       return (
    //         <Box
    //           borderWidth={1}
    //           w="full"
    //           p={8}
    //           borderColor="#00a1b8"
    //           borderRadius="md"
    //         >
    //           <VStack spacing={3} alignItems="flex-start">
    //             <Text>
    //               Standard Home Warranty Plan for a new single-family home,
    //               condominium, townhome or mobile home for years 2-4 :
    //             </Text>
    //             <Text>
    //               <b>$525</b>
    //               <br />
    //               Need COVERAGE UPGRADES?
    //             </Text>
    //             <Text>
    //               Call Acclaimed at <b>888-494-9460</b>
    //             </Text>
    //             <Button
    //               bg={Colors.green}
    //               color={useColorModeValue("white", "gray.800")}
    //               _hover={{
    //                 bg: "green.600",
    //               }}
    //             >
    //               CONTINUE
    //             </Button>
    //           </VStack>
    //         </Box>
    //       );
    //     }
    //   else {
    //     return (
    //       <Box
    //         borderWidth={1}
    //         w="full"
    //         p={8}
    //         borderColor="#00a1b8"
    //         borderRadius="md"
    //       >
    //         <VStack spacing={3} alignItems="flex-start">
    //           <Text>This order requires special assistance.</Text>
    //           <Text>
    //             Call an Acclaimed Home Warranty agent at:{" "}
    //             <Button variant="link" color="green.600">
    //               888-494-9460
    //             </Button>
    //           </Text>
    //           <Text>
    //             We will be able to add{" "}
    //             <Button variant="link" color="blue.600">
    //               COVERAGE UPDGRADES
    //             </Button>
    //             , finalize you order, and provide an invoice.
    //           </Text>
    //         </VStack>
    //       </Box>
    //     );
    //   }
    // } else if (homeType == "condominium") {
    //   return (
    //     <Box
    //       borderWidth={1}
    //       w="full"
    //       p={8}
    //       borderColor="#00a1b8"
    //       borderRadius="md"
    //     >
    //       <VStack spacing={3} alignItems="flex-start">
    //         <Text>
    //           Standard Home Warranty Plan for a condominium, townhome or mobile
    //           home less than 2k sq ft:
    //         </Text>
    //         <Text>
    //           <b>$285</b>
    //           <br />
    //           Need COVERAGE UPGRADES?
    //         </Text>
    //         <Text>
    //           Call Acclaimed at <b>888-494-9460</b>
    //         </Text>
    //         <Button
    //           bg={Colors.green}
    //           color={useColorModeValue("white", "gray.800")}
    //           _hover={{
    //             bg: "green.600",
    //           }}
    //         >
    //           CONTINUE
    //         </Button>
    //       </VStack>
    //     </Box>
    //   );
    // }

    //if (homeType == "condominium") {
    //    return (
    //        <Box
    //            borderWidth={1}
    //            w="full"
    //            p={8}
    //            borderColor="#beeb9e"
    //            borderRadius="md"
    //        >
    //            <VStack spacing={3} alignItems="flex-start">
    //                <Text>
    //                    Standard Home Warranty Plan for a condominium, townhome or mobile
    //                    home less than 2,000 square feet:
    //                </Text>
    //                <Text>
    //                    <b>$285</b>
    //                    <br/>
    //                    Need COVERAGE UPGRADES?
    //                </Text>
    //                <Text>
    //                    Call Acclaimed at <b>888-494-9460</b>
    //                </Text>
    //                <Button
    //                    bg={Colors.green}
    //                    color={useColorModeValue("white", "gray.800")}
    //                    _hover={{
    //                        bg: "green.600",
    //                    }}
    //                >
    //                    CONTINUE
    //                </Button>
    //            </VStack>
    //        </Box>
    //    );
    //}
    return (
        <Box borderWidth={1} w="full" p={8} borderColor="#beeb9e" borderRadius="md">
            <HStack alignItems="flex-start" spacing={3}>
                <VStack alignItems="flex-start" flex={1}>
                    <Text mb={2} fontSize="xl">
                        The base price for this coverage is {minValue}
                    </Text>
                    <Text>
                        The remaining balance can be applied towards Coverage Upgrades or
                        service calls.
                    </Text>
                </VStack>
                <VStack flex={2} alignItems="flex-start">
                    <Input
                        type="number"
                        min={minValue}
                        max={2000}
                        w={300}
                        value={inputValue}
                        onChange={(e) => onChange(e.target.value)}
                    />
                    <Text>
                        Enter an amount from <b>${minValue} - $2000</b>
                    </Text>
                    <Button
                        bg={Colors.green}
                        color={useColorModeValue("white", "gray.800")}
                        _hover={{
                            bg: "green.600",
                        }}
                        onClick={onGo}
                    >
                        GO
                    </Button>
                </VStack>
            </HStack>
        </Box>
    );
};

const RealEstateOrder = (props: any) => {
    // const locations = props.data.allContentfulLocations?.nodes;
    const [locations, setLocations] = React.useState<any>();
    const [agentType, setAgentType] = React.useState<string>("");
    const [coverageTerm, setCoverageTerm] = React.useState(false);
    const [homeType, setHomeType] = React.useState("");
    const [newHome, setNewHome] = React.useState(false);
    const [under6K, setUnder6K] = React.useState(null);


    useEffect(() => {
        getPlans().then(value => {
            setLocations(value?.results)
        });
    }, [])

    useEffect(() => {
        if (!props?.location?.state?.locationName) navigate(-1);
    }, [])


    const locationValues: Record<string, string> = {};
    locations?.forEach((loc: any) => {
        locationValues[loc.name.toLowerCase()] = loc.name.toLowerCase();
    });

    const showCoverageQuestions = React.useMemo(
        () =>
            agentType === "buyer" || agentType === "buyer_agent" || agentType === "escrow_officer" || (agentType === "listing_agent" && coverageTerm),
        [agentType, coverageTerm]
    );


    const onCoverageChanged = (type: string) => {
        setAgentType(type);
    };

    const onTermsChanged = (status: boolean) => {
        setCoverageTerm(status);
    };

    const onChangeCoverageQuestions = (
        options: any
    ) => {
        setHomeType(options.homeType);
        setNewHome(options.isNewHome);
        setUnder6K(options.square);
    };

    const onContinue = (amount: number) => {
        navigate(`/real-estate-plans/${props?.location?.state?.locationName?.toLowerCase()}/`, {
            state: {
                amount,
                agentType,
                homeType,
                newHome,
                under6K
            }
        })
    };

    return (
        <>
            <SEO title="Real Estate Order" description="Acclaimed Home Warranty"/>
            <NavBar/>

            <main>
                {/** Banner */}
                <div className="w-full" style={{overflow: "visible"}}>
                    <Container maxW={['lg', '6xl']} pt="12" position="relative">
                        <HStack w="full" justifyContent="center" alignItems="flex-end" pl={{base: "40px", lg: "150px"}}>
                            <Box w={{base: "200px", lg: "400px"}} position="absolute" left="0" bottom="-20px"
                                 display={{base: "none", sm: "block"}}>
                                <StaticImage src="../images/norman-thank-you.png" alt="AcclaimedHW contact us"/>
                            </Box>
                            <VStack gap="0" lineHeight="0.8" pb="20px">
                                <Text textAlign="center" fontSize={{base: '3xl', md: '5xl', lg: '7xl'}} textColor="#000"
                                      fontFamily="'League Spartan', sans-serif" fontWeight="light">REAL ESTATE</Text>
                                <Box textAlign="center" fontSize={{base: '3xl', md: '5xl', lg: '7xl'}} textColor="#000"
                                     mb="20px" fontFamily="'League Spartan', sans-serif" fontWeight="light">ORDERS
                                    <Text textAlign="center" fontSize={{
                                        base: 'xl',
                                        md: '2xl',
                                        lg: '4xl'
                                    }}> ({props?.location?.state?.locationName ? props?.location?.state?.locationName : ''}) </Text>
                                </Box>
                            </VStack>
                        </HStack>
                    </Container>
                    <Box w="full" h="12" background="#01a1ba" py="2">
                    </Box>
                </div>
                <Container maxW={["lg", "6xl"]} mt="20px" mb="40px">
                    <VStack spacing="30px">
                        {props?.location?.state?.locationName && (
                            <SelectContainer
                                title="Let us know what type of coverage this is..."
                                subtitle="I am the ..."
                                values={{
                                    buyer_agent: "Buyer Agent",
                                    buyer: "Buyer",
                                    listing_agent: "Listing Agent",
                                    escrow_officer: "Escrow Officer"
                                }}
                                onChange={onCoverageChanged}
                            />
                        )}

                        {agentType === "listing_agent" && (
                            <ListingSellerCoverageTerms onChange={onTermsChanged}/>
                        )}

                        {showCoverageQuestions && agentType !== 'listing_agent' && (
                            <CoverageQuestions onChange={onChangeCoverageQuestions}/>
                        )}

                        {((under6K !== null && agentType !== 'listing_agent' && homeType)
                            || (coverageTerm && agentType === 'listing_agent')) && (
                            <FinalSteps onContinue={onContinue} homeType={homeType} agentType={agentType}
                                        under6K={under6K} newHome={newHome}/>
                        )}
                    </VStack>
                </Container>
                {/* {locations.map((loc: any) => {
          // console.log({loc}) 
          return (
            <div><a href={`/real-estate-location/${loc.slug}`}>{loc.locationName}</a></div>
          )
        })} */}
            </main>
            <Footer/>
        </>
    );
};

export default RealEstateOrder;

export const query = graphql`
  query RealEstatePageQuery {
    allContentfulLocations {
      nodes {
        id
        locationName
        locationBackground {
          file {
            url
          }
        }
        slug
        serviceFee
        serviceFeeAdditional
      }
    }
  }
`;
