import {axios} from "../../lib/axios";

export class BidBoxConfig {

    //  toggle this and leave everything else alone
    public static readonly AUTHNET_PROD = true;

    public static readonly order_token = BidBoxConfig.AUTHNET_PROD ? 'jkYLsaXZGmCO8lJ7' : 'jkYLsaXZGmCO8lJ7';
    public static readonly host = BidBoxConfig.AUTHNET_PROD ? 'https://core-acclhw.bidboxpro.com' : 'http://localhost:8000';


    public static readonly AUTHNET_ID = BidBoxConfig.AUTHNET_PROD ? '9Tw6u6dZp' : '5bSV36j4R';
    public static readonly AUTHNET_CLIENT_KEY =
        BidBoxConfig.AUTHNET_PROD ?
            '33VD89cU9dfVC6FTxAQcY5gEsx4Q6Ug5ZtD435tV5XhcCQZHhwF6KZs5WUP5r998' :
            '5J8fj7Rn7pg5nF5JgFN8M6Nfyfa7NsRn78nUu922CPqetq2J45368VrBC9TJSw2w';


    public static readonly planUri = '/policy/group-token/?order_token=';
    public static readonly orderUri = '/warranty/token/?order_token=';


}

export interface PlanCoverage {
    benefit?: false,
    cost: number,             // this is mapped to retail_sp for backwards compatibility in create-pages.ts
    cost_monthly: number,     // ditto, but for retail_sp_monthly
    retail_sp: number,        // these two are provided by the API
    retail_sp_monthly: number,//  "
    created?: string,
    id: number,
    max?: number,
    optional?: boolean,
    plan?: number,
    system: string,
    tag?: string,
    qty?: number,
    enabled: boolean
}

export interface Pic {
    id: number,
    thumb: string,
    url: string
}

export interface Doc {
    id: number,
    name: string,
    size: number,
    url: string,
    type: string,
}

export type PropType = "SingleFamily" | "Duplex" | "Triplex" | "Fourplex" | "MobileHome"

export interface WarrantyPlan {
    affiliate_sp: number,
    affiliate_sp_monthly: number,
    agency_compensation: boolean,
    coverage: PlanCoverage[],
    created: string,
    duration: number,
    enabled: boolean,
    retail_sp: number,
    features: string[],
    group: number,
    id: number,
    is_taxable: boolean,
    is_template: boolean,
    max_claim: number,
    name: string,
    new_construction: boolean,
    notify_active_homeowner: boolean,
    notify_cc_homeowner_if_propmgr: boolean,
    notify_claim_approved_homeowner: boolean,
    notify_claim_closed_affiliate: boolean,
    notify_claim_closed_homeowner: boolean,
    notify_invoice_affiliate: boolean,
    notify_pending_affiliate: boolean,
    notify_pending_homeowner: boolean,
    pic: Pic,
    plan_type: "Standard" | "Listing" | string,
    retail_sp_monthly: number,
    prop_types: [PropType],
    renewal_sp: number,
    renewal_sp_monthly: number,
    service_fee: number,
    welcome_packet: Doc,
}


export interface PlanGroup {
    choose_prop_type: string,
    created: string,
    id: number,
    name: string,
    pic: Pic,
    policies: WarrantyPlan[]
}

export interface PlanGroupResults {
    results: PlanGroup[];
}

export const getPlans = (): Promise<PlanGroupResults> => {
    //DEBUG: console.log("Accessing URL: " + BidBoxConfig.host + BidBoxConfig.planUri + BidBoxConfig.order_token);
    return axios.get(BidBoxConfig.host + BidBoxConfig.planUri + BidBoxConfig.order_token);

}

export interface OrderData {
    policy_id?: number
    is_monthly?: boolean
    is_affiliate_price?: boolean
    payment_type?: string
    nonce_desc?: string
    nonce_val?: string
    billto_first?: string
    billto_last?: string
    tax_rate?: number
    type?: string
    options?: Array<{ coverage_id: number, qty: number }>
    subscription_id?: string | null
    buyer_agent_name?: string
    buyer_agent_email?: string
    buyer_agent_phone?: number
    buyer_agent_brokerage?: string
    seller_agent_name?: string
    seller_agent_email?: string
    seller_agent_phone?: number
    seller_agent_brokerage?: string
    title_company?: string
    title_email?: string
    title_phone?: number
    title_name?: string
    tc_company?: string
    tc_email?: string
    tc_phone?: number
    tc_name?: string
    lender_company?: string
    lender_email?: string
    lender_phone?: number
    lender_name?: string
    closing?: string | null
    note?: string
    who_pays?: string
    who_placed?: string
    name?: string
    email?: string
    phone?: number
    related?: number
    origin?: string
    street?: string
    city?: string
    zip?: number
    state?: string
    lat?: number
    lon?: number
    place_id?: string
}

export const placeOrder = (data: OrderData) => {
    return axios.post(BidBoxConfig.host + BidBoxConfig.orderUri + BidBoxConfig.order_token, data);
}


export class repInfo {
    static CHOOSE = "Choose...";

    val?: string;

    constructor(public display: string) {
        this.val = display;
        if (this.display == repInfo.CHOOSE) {
            this.val = '';
        }
    }
}

export const salesReps: repInfo[] = [
    new repInfo(repInfo.CHOOSE),
    new repInfo('Alena Adams'),
    new repInfo('Amanda McDonald'),
    new repInfo('David Modrow'),
    new repInfo('Lesa Beuchert'),
    new repInfo('Nate Berrett'),
    new repInfo('Paul Anderson'),
    new repInfo('Sandra Perkins'),
    new repInfo('Spencer Adams'),
    new repInfo('Other'),
];
